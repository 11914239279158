@import url('https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@400;500;900&family=Hepta+Slab:wght@300;400;600&display=swap');

@mixin titleFont {
  font-family: 'Grenze Gotisch', cursive;

}

@mixin mainFont {
  font-family: 'Hepta Slab', serif;
}


$agave: #00aa96;

$darkTequila: #eed98c;
$darkCork: #dabf96;
$darkBottle: #c7d2d0;

$yeller: #f7d317;
$pink: #ed2bd4;
$lightBlue: #2bede1;


$bg: adjust-color($agave, $lightness: -40%);
$itemBG: rgba(adjust-color(adjust-hue($darkCork, 180deg), $lightness: -66%), 0.8);

$fontColor: $darkBottle;
$specialFontColor: $darkTequila;

$borderColor: $darkCork;
$borderColor90: adjust-hue($borderColor, 90deg);
$borderColor120: adjust-hue($borderColor, 120deg);
$effectColor: $darkBottle;

@mixin buttonSizing ($fontSize) {
  $fS: $fontSize;
  $pV: $fS/4;
  $pH: $fS/2;
  $bW: $fS/12;
  padding: $pV $pH;
  border-radius: $pV;
  border: $bW solid $borderColor;
  font-size: $fS;
}

$HeaderHeight: 128px;
$PoolWidth: 640px;

body, button, input, p {
  @include mainFont;
}

body {
  background: $bg;
  font-weight: 300;
}
button {
  font-weight: 600;
}


// ---------------------------------------------------------------------------------------
// HEADER --------------------------------------------------------------------------
header {
  $h: $HeaderHeight;
  $logoHeight: $h/2;
  $spaceAboveAndBelowLogo: $h - $logoHeight;

  position: fixed;
  top:0;
  left: 0;
  right:0;
  bottom: calc(100vh - #{$h});
  img {
    position: absolute;
    left: $spaceAboveAndBelowLogo/2;
    height: $h/2;
    top: $spaceAboveAndBelowLogo/2;
    //filter: drop-shadow(0 0 4px invert($bg, $weight: 100%));
    cursor: pointer;
  }

  .App-name {
    @include titleFont;
    font-weight: 900;
    position: absolute;
    left: $logoHeight + $spaceAboveAndBelowLogo;
    top: 0;
    font-size: $logoHeight;
    line-height: $h;
    color: $specialFontColor;
    letter-spacing: 0;
    transform: scaleX(1) translateX(0) translateY(0);
    //transform: scale(0) translateX(-200px);
    transition: 0.2s transform ease-in-out;

  }

  img:hover + .App-name {
    transform: scaleX(1) translateX(0);
  }

}


.metamask-btn {

  $fS: 16px;
  $pV: $fS/4;
  $pH: $fS/2;
  $bW: $fS/8;

  $calcH: $pV*2 + $fS + $bW*2;

  cursor: pointer;

  position: fixed;
  top: ($HeaderHeight - $calcH)/2;
  right: ($HeaderHeight - $calcH)/2;

  @include buttonSizing(16px);

  outline: none;
  background: none;
  color: $borderColor;
  
  transform: scale(1);
  transition: 0.2s transform ease-in-out,
              0.2s background ease-in-out, 
              0.2s color ease-in-out;

  &:hover {
    background: $bg;
    color: $effectColor;
    transform: scale(1.1);
  }

}

// ---------------------------------------------------------------------------------------
// Pools --------------------------------------------------------------------------
.farmland {
  width: auto;
  box-sizing: border-box;
  padding: $HeaderHeight 64px 64px;
}


.pool {
  $w: $PoolWidth;
  $poolFontSize: $w/25;
  $gutter: $poolFontSize;

  font-size: $poolFontSize;

  display: inline-block;
  margin: $gutter;

  box-sizing: border-box;
  border: 1px solid rgba($borderColor, 0.5);
  border-radius: $gutter;
  width: $w;
  padding: $gutter;

  color: $fontColor;
  background: $itemBG;

  filter: drop-shadow(2px 2px 2px black);
  
  &__name {
    img {
      height: $poolFontSize*2;
      margin-left: $poolFontSize/4;
      position: relative;
      top: $poolFontSize/2 - 2px + $poolFontSize/8;
      filter: drop-shadow(2px 2px 2px black);
    }
    img.moonwasp {
      filter: drop-shadow(0 0 8px $specialFontColor);
    }
  }

  .arrows {
    margin-right: $poolFontSize/2;
    margin-left: $poolFontSize/2;
    color: $effectColor;
  }

  &__textName {
    font-size: $poolFontSize*2.5;
    @include titleFont;
    font-weight: 500;
    color: $specialFontColor;
    margin-top: $poolFontSize/8;
    margin-bottom: $poolFontSize/8;
  }

  &__rewards {
    margin-top: $poolFontSize/10;
    border-top: 1px dashed $borderColor;
    border-bottom: 1px dashed $borderColor;
    padding-top: $poolFontSize/10;
    padding-bottom: $poolFontSize/10;
    margin-bottom: $poolFontSize/10;
  }


  button {
    @include buttonSizing($poolFontSize);
    outline: none;

    background: $itemBG;
    color: $borderColor;

    margin: 2px $poolFontSize/4;
    cursor: pointer;
    transform: scale(1);
    border-radius: $poolFontSize/4;

    transition: 0.2s transform ease-in-out, 
                0.2s background ease-in-out,
                0.2s border-color ease-in-out,  
                0.2s color ease-in-out;
    &:hover {
      background: adjust-hue($itemBG, 180deg);
      color: adjust-hue($borderColor, 120deg);
      border-color: adjust-hue($borderColor, 120deg);
      transform: scale(1.1);

    }
  }

  .pool__controls {
    text-align: right;
    margin-top: $gutter;
  }

  &__rate {
    position: absolute;
    top: $gutter/2;
    right: $gutter;
    font-size: 16px;
    padding: $poolFontSize/8 $poolFontSize/4;
    background: $bg;
  }

}



// ---------------------------------------------------------------------------------------
// Input Overlay --------------------------------------------------------------------------

$inputOverlayButtonSize: 20px;
$inputOverlayButtonHeight: 7*$inputOverlayButtonSize/4;
$inputOverlayGutter: 16px;
.btn {


  @include buttonSizing($inputOverlayButtonSize);
  outline: none;
 
  background: $itemBG;
  color: $borderColor;

 
  cursor: pointer;
  transform: scale(1);
  border-radius: 4px;
  filter: drop-shadow(2px 2px 2px black);

  transition: 0.2s transform ease-in-out, 
              0.2s background ease-in-out, 
              0.2s color ease-in-out, 
              0.2s filter ease-in-out;
  &:hover {
    background: $bg;
    color: $effectColor;
    transform: scale(1.1);
    filter: drop-shadow(4px 4px 4px black);

  }


}


.theInput {
  &.TOGGLE__theInput--false {
    display: none;
  }
  &.TOGGLE__theInput--true {
    display: block;
  }

  $w: $PoolWidth;


  position: fixed;
  text-align: center;
  background: rgba($itemBG, 0.9);
  top: 0;
  left: 0;
  right: 0; 
  bottom: 0;

  $inputH: $w/6.5;
  $like8px: $w/39;
  input {
    display: block;
     outline: none;
    border: $like8px/4 solid rgba($borderColor, 0.5);
    background: $itemBG;
    color: $borderColor;
    padding: 0 $like8px;
    width: $w;

    filter: drop-shadow(2px 2px 2px black);
    

    $h: $inputH;
    height: $h;
    font-size: $inputH*2/3;
    line-height: $inputH;
    box-sizing: border-box;

    margin: calc(50vh - #{$h}/2) auto;
    cursor: pointer;
    transform: scale(1);
    border-radius: $like8px/2;

  }

  .btn--max {
    position: fixed;
    top: calc(50vh - #{$inputH}/2 + #{$inputOverlayButtonHeight} - #{$inputOverlayGutter}/2);
    right: calc(50vw - #{$w}/2 + #{$inputOverlayGutter});
  }
  .btn--back {
    position: fixed;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2);
    left: calc(50vw - #{$w}/2);
  }

  .btn--approve, .btn--withdraw {
    position: fixed;
    top: calc(50vh + #{$inputH}/2 + #{$inputOverlayGutter});
    right: calc(50vw - #{$w}/2);
  }

  .btn--deposit {
    position: fixed;
    top: calc(50vh + #{$inputH}/2 +  #{$inputOverlayGutter}*2 + #{$inputOverlayButtonHeight});
    right: calc(50vw - #{$w}/2);
  }

  &__balance {
    position: fixed;
    top: calc(50vh - #{$inputH} + #{$inputOverlayGutter}/2);
    right: calc(50vw - #{$w}/2);
    color: $borderColor;
    font-size: $inputOverlayButtonSize;

  }

  &__allowance {
    position: fixed;
    top: calc(50vh - #{$inputH} - #{$inputOverlayGutter}/2 - #{$inputOverlayButtonSize});
    right: calc(50vw - #{$w}/2);
    color: $borderColor;
    font-size: $inputOverlayButtonSize;
  }


  &.INTENT__theInput--add {
    .btn--approve, .btn--deposit {
      display: block;
    }
    .btn--withdraw {
      display: none;
    }
  }
  &.INTENT__theInput--remove {
    .btn--approve, .btn--deposit {
      display: none;
    }
    .btn--withdraw {
      display: block;
    }
  }
}


.pending {
  $penderH: 48px;
    position: fixed;
    top:0;
    left: 0;
    height: $penderH;
    line-height: $penderH;
    width: 100%;
    background: $darkCork;
    color: $bg;
    text-align: center;
    font-size: $penderH*2/3;
    @include titleFont;
    font-weight: 500;
    &--false {
      display: none;
    }
  }

.info {
  border-top: 1px solid $borderColor;
  padding: 16px;
  color: $fontColor;

  a {
    text-decoration: none;
    color: $darkTequila;
    font-weight: 600;
    &:hover {
      color: adjust-hue($darkTequila, 60deg);
    }
    & + a {
      margin-left: 16px;
    }
  }

  p {
    font-weight: 400;

  }
}

strong {
  font-weight: 400;
  @include titleFont;
  font-size: 1.4em;
}